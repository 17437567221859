<template>
    <div class="reg">
        <div class="regtop flexab ">
            <img :src="utils.loadImg('logo2.png')" alt=""  />
            <!-- <div class="rig flex flexa padr40 "> -->
            <div class="rig flex flexa padr40 point" @click="tab=2">
                <img :src="utils.loadImg('ma.png')" alt="" width="25" height="25" />
            </div>
        </div>

        <div v-if="tab==1" class="regmid bgf">
            <div class="tc midhead borbot">
                <p class="font12 lef">已有账号？<router-link to="/login" class="colzhuti">立即登录</router-link> </p>
                <p class="col24 font22 fontw6">欢迎注册海量数据 | 工单系统</p>
            </div>
            <div class="wmid">
                <p class="h40"></p>
                <el-form
                    :model="param"
                    :rules="rules"
                    class="demo-ruleForm"
                >
                    <el-form-item  prop="username">
                        <el-input placeholder="用户名" v-model="param.username" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="email">
                        <el-input placeholder="邮箱号" v-model="param.email" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="mobile">
                        <el-input placeholder="请输入您的手机号" v-model="param.mobile" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="password">
                        <el-input placeholder="输入密码" v-model="param.password" show-password size="large" type="password" />
                    </el-form-item>

                    <el-form-item  prop="password">
                        <el-input v-model="password" placeholder="再次输入密码" size="large" type="password" />
                    </el-form-item>

                    <el-form-item  prop="yzm">
                        <div class="flex nowrap">
                            <el-input v-model="param.yzm" placeholder="输入6位验证码" size="large"/>
                            <el-button @click="handleGetSms" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '发送验证码'}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <el-input v-model="param.CSINumber" placeholder="客户服务号（CSI）（非必填）" size="large"/>

                <p class="col98 font10 fontw4 lh16 padt10">*选填 公司在购买产品后都有唯一的客户服务号（CSI），只有关联了CSI号且CSI在有效期内的账户才能提交对应的技术工单</p>
                <div class="padt40 padb24">
                    <el-checkbox v-model="checked"  class="col716e font12">
                        我已阅读并同意
                        <el-link href="https://support.vastdata.com.cn/api/api/th-txt/privacy-policy" target="_blank" :underline="false" class="colblue">《隐私政策》</el-link>
                        <el-link href="https://support.vastdata.com.cn/api/api/th-txt/agreement" target="_blank" :underline="false" class="colblue">《海量数据服务协议》</el-link>
                    </el-checkbox>
                </div>
                <el-button @click="submit" type="primary" class="w100" size="large" >
                    立即注册
                </el-button>
            </div>
        </div>
        <div v-if="tab==2" class="regmid bgf">
            <div class="tc midhead flexa flexc flex borbot">
                <img :src="utils.loadImg('wx.png')" alt="" />
                <p class="col24 font22 fontw6 padl14">微信扫码关联</p>
            </div>
            <div class="wmid tc">
                <div style="height:50px"></div>
                <p class="col24 font14 lh30">扫码关联微信以方便您</p>
                <p class="col24 font14 lh30 padb20">使用微信关联账号，接受工单消息提醒</p>

                <img :src="utils.loadImg('ma2.png')" alt="" srcset="">

                <p @click="tab=1" class="colzhuti font14 point padt30">暂不设置</p>
            </div>
        </div>
        <p style="height: 137px;"></p>
        <div class="botxt">
            <p class="col6 tc font12 padt40 lh20">版权所有：Copyright 2021 版权所有 海量数据</p>
            <p class="col6 tc font12 lh20">京ICP备10213903号-3 京公网安备11010802016346</p>
        </div>
    </div>
</template>
<script>
import { registered, sendVerificationCode } from "@/utils/api1.js"

export default {
    data(){
        const checkMobile = (rule, value, callback) => {
            console.log(value);
            if (!value) {
                callback(new Error("请输入手机号！"));
            } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                callback(new Error("当前输入号码有误，请核对后再次进行输入"));
            } else {
                callback();
            }
        };
        const checkEmail = (rule, value, callback) => {
            console.log(value);
            const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
            if (!value) {
                callback(new Error("请输入邮箱号！"));
            } else if (!reg.test(value)) {
                callback(new Error("当前输入邮箱号有误，请核对后再次进行输入"));
            } else {
                callback();
            }
        };
        return{
            tab: 1,
            param:{
                mobile: '',
                yzm: '',            
            },
            checked: true,
            password:'',
            yzm: '',            
            time:60,
            yzmSend: false,
            timmer: null,
            rules:{
                // username: [ { required: true, message: '请输入用户名！', trigger: 'blur', }, ],
                // email: [ { validator: checkEmail, trigger: 'blur' }],
                // mobile: [ { validator: checkMobile, trigger: 'blur' }],
                // password: [ { required: true, message: '请输入密码！', trigger: 'blur', }, ],
                // yzm: [ { required: true, message: '请输入密码！', trigger: 'blur', }, ],
            },
        }
    },
    methods: {
        submit(){
            var that = this;
            if(!that.param.username){
                this.$message.warning('请填写用户名！');
            }else if(!that.param.email){
                this.$message.warning('请填写邮箱！');
            }else if(!that.param.mobile){
                this.$message.warning('请填手机号！');
            }else if(!that.param.password){
                this.$message.warning('请输入密码！');
            }else if(!that.password){
                this.$message.warning('请再次输入密码！');
            }else if(!that.param.yzm){
                this.$message.warning('请填写验证码！');
            }else if(that.param.password != that.password ){
                this.$message.warning('密码前后输入不正确！');
            }else if(!that.checked){
                this.$message.warning('请勾选 “我已阅读并同意《隐私政策》《海量数据服务协议》” ！');
            }else{
                registered(that.param).then(res=>{
                    console.log(res);
                    if(res){
                        this.$message.success("注册成功，请登录");
                        this.$router.push('/login')
                    }
                })
            }
        },
       
        handleGetSms() {
            let that = this;
            console.log('sss');
            if (that.yzmSend) return;
            console.log('s12ss');

            sendVerificationCode({mobile:that.param.mobile}).then(res=>{
                console.log(res);
                if(res){
                    that.$message.success("验证码发送成功！");
                }
            })
            console.log('99s12ss');
            
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.botxt{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 37px;
    box-sizing: border-box;
}
.regtop{
    background: #34383F;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
    z-index: 10;
    box-sizing: border-box;
}
.reg{
    padding: 90px 0 0px;
    box-sizing: border-box;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
    .regmid{
        width: 1090px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 64px 57px;
    }
    .wmid{
        width: 320px;
        margin: 0 auto;
    }
    .midhead{
        line-height: 100px;
        position: relative;
        .lef{
            position: absolute;
            left: 0;
        }
    }
}
.btn1{
    margin-left: 10px;
    border-radius: 2px;
    color: #71767E;
    background: #F1F1F1;
    font-size: 12px;
    width: 110px;
    min-width: 110px;
}

</style>